import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authFB } from '../../helpers/Firebase';
import { servicePath } from '../../constants/defaultValues';
import { NotificationManager } from '../../components/common/react-notifications';
import { 
  REQUEST_DETAIL_BMP_AGENCY_MAKER_LIST,
  REQUEST_DETAIL_BMP_AGENCY_MAKER_SEARCH_GEO_COUNTRY_LIST,
  REQUEST_DETAIL_BMP_AGENCY_MAKER_SEARCH_HEALTH_AGENCY_LIST,
  REQUEST_DETAIL_BMP_AGENCY_MAKER_ADD,
  REQUEST_DETAIL_BMP_AGENCY_MAKER_DETAIL,
  REQUEST_DETAIL_BMP_AGENCY_MAKER_UPDATE,
} from '../actions';
import {
  RequestDetailBmpAgencyMakerListSuccess,
  RequestDetailBmpAgencyMakerListError,
  RequestDetailBmpAgencyMakerSearchGeoCountryListSuccess,
  RequestDetailBmpAgencyMakerSearchGeoCountryListError,
  RequestDetailBmpAgencyMakerSearchHealthAgencyListSuccess,
  RequestDetailBmpAgencyMakerSearchHealthAgencyListError,
  RequestDetailBmpAgencyMakerAddSuccess,
  RequestDetailBmpAgencyMakerAddError,
  RequestDetailBmpAgencyMakerDetailSuccess,
  RequestDetailBmpAgencyMakerDetailError,
  RequestDetailBmpAgencyMakerUpdateSuccess,
  RequestDetailBmpAgencyMakerUpdateError
} from '../actions';
 
export function* watchRequestDetailBmpAgencyMakerList() {
  yield takeEvery(REQUEST_DETAIL_BMP_AGENCY_MAKER_LIST, onRequestDetailBmpAgencyMakerList);
}

export function* watchRequestDetailBmpAgencyMakerSearchHealthAgencyList() {
  yield takeEvery(REQUEST_DETAIL_BMP_AGENCY_MAKER_SEARCH_HEALTH_AGENCY_LIST, onRequestDetailBmpAgencyMakerSearchHealthAgencyList);
}

export function* watchRequestDetailBmpAgencyMakerSearchGeoCountryList() {
  yield takeEvery(REQUEST_DETAIL_BMP_AGENCY_MAKER_SEARCH_GEO_COUNTRY_LIST, onRequestDetailBmpAgencyMakerSearchGeoCountryList);
}

export function* watchRequestDetailBmpAgencyMakerAdd() {
  yield takeEvery(REQUEST_DETAIL_BMP_AGENCY_MAKER_ADD, onRequestDetailBmpAgencyMakerAdd);
}

export function* watchRequestDetailBmpAgencyMakerDetail() {
  yield takeEvery(REQUEST_DETAIL_BMP_AGENCY_MAKER_DETAIL, onRequestDetailBmpAgencyMakerDetail);
}

export function* watchRequestDetailBmpAgencyMakerUpdate() {
  yield takeEvery(REQUEST_DETAIL_BMP_AGENCY_MAKER_UPDATE, onRequestDetailBmpAgencyMakerUpdate);
}

const onRequestDetailBmpAgencyMakerListAsync  = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_detail_bmp_agency_maker/list', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

const onRequestDetailBmpAgencyMakerUpdateAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_detail_bmp_agency_maker/update', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json', 'token': token },
      body: JSON.stringify(data)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

function getToken(auth) {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      user.getIdToken().then((token) => {
        unsubscribe();
        resolve(token);
      });
    }, reject);
  });
}


const onRequestDetailBmpAgencyMakerAddAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_detail_bmp_agency_maker/add', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json', 'token': token },
      body: JSON.stringify(data.payload)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

const onRequestDetailBmpAgencyMakerSearchGeoCountryListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/geo_country', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onRequestDetailBmpAgencyMakerSearchHealthAgencyListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/health_agency', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onRequestDetailBmpAgencyMakerDetailAsync  = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_detail_bmp_agency_maker/detail', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

function* onRequestDetailBmpAgencyMakerList(data) {
  try {
    const response = yield call(onRequestDetailBmpAgencyMakerListAsync, data.payload);
    yield put(RequestDetailBmpAgencyMakerListSuccess(response.data));
  } catch (error) {
    yield put(RequestDetailBmpAgencyMakerListError(error));
  }
}

function* onRequestDetailBmpAgencyMakerSearchHealthAgencyList(data) {
  try {
    const response = yield call(onRequestDetailBmpAgencyMakerSearchHealthAgencyListAsync, data.payload);
    yield put(RequestDetailBmpAgencyMakerSearchHealthAgencyListSuccess(response.data));
  } catch (error) {
    yield put(RequestDetailBmpAgencyMakerSearchHealthAgencyListError(error));
  }
}

function* onRequestDetailBmpAgencyMakerSearchGeoCountryList(data) {
  try {
    const response = yield call(onRequestDetailBmpAgencyMakerSearchGeoCountryListAsync, data.payload);
    yield put(RequestDetailBmpAgencyMakerSearchGeoCountryListSuccess(response.data));
  } catch (error) {
    yield put(RequestDetailBmpAgencyMakerSearchGeoCountryListError(error));
  }
}

function* onRequestDetailBmpAgencyMakerAdd(data) {
  try {
      const response = yield call(onRequestDetailBmpAgencyMakerAddAsync, data);
      console.log(response)
      if (response.data.status === true) {
          NotificationManager.success(response.data.message, 'Exito', 3000, null, null, '');
      } else if (response.data.status === 'error') {
          NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(RequestDetailBmpAgencyMakerAddSuccess(response.data));
  } catch (error) {
      yield put(RequestDetailBmpAgencyMakerAddError(error));
      NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
  }
}

function* onRequestDetailBmpAgencyMakerDetail(data) {
  try {
    const response = yield call(onRequestDetailBmpAgencyMakerDetailAsync, data.payload);
    yield put(RequestDetailBmpAgencyMakerDetailSuccess(response.data.data));
  } catch (error) {
    yield put(RequestDetailBmpAgencyMakerDetailError(error));
  }
}

function* onRequestDetailBmpAgencyMakerUpdate(data) {
  try {
      const response = yield call(onRequestDetailBmpAgencyMakerUpdateAsync, data.payload);
      if (response.data.status === true) {
          NotificationManager.success(response.data.message, 'Exito', 3000, null, null, '');
      } else if (response.data.status === 'error') {
          NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(RequestDetailBmpAgencyMakerUpdateSuccess(response.data));
  } catch (error) {
      yield put(RequestDetailBmpAgencyMakerUpdateError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchRequestDetailBmpAgencyMakerList),
    fork(watchRequestDetailBmpAgencyMakerSearchGeoCountryList),
    fork(watchRequestDetailBmpAgencyMakerSearchHealthAgencyList),
    fork(watchRequestDetailBmpAgencyMakerAdd),
    fork(watchRequestDetailBmpAgencyMakerDetail),
    fork(watchRequestDetailBmpAgencyMakerUpdate),
  ]);
} 