import { createStructuredSelector } from 'reselect'

import {
  REQUEST_DETAIL_BMP_AGENCY_MAKER_LIST,
  REQUEST_DETAIL_BMP_AGENCY_MAKER_LIST_SUCCESS,
  REQUEST_DETAIL_BMP_AGENCY_MAKER_LIST_ERROR,
  REQUEST_DETAIL_BMP_AGENCY_MAKER_PAGE_SET,
  REQUEST_DETAIL_BMP_AGENCY_MAKER_SEARCH_SET,
  REQUEST_DETAIL_BMP_AGENCY_MAKER_SEARCH_CHANGE,
  REQUEST_DETAIL_BMP_AGENCY_MAKER_SEARCH_GEO_COUNTRY_LIST,
  REQUEST_DETAIL_BMP_AGENCY_MAKER_SEARCH_GEO_COUNTRY_LIST_SUCCESS,
  REQUEST_DETAIL_BMP_AGENCY_MAKER_SEARCH_GEO_COUNTRY_LIST_ERROR,
  REQUEST_DETAIL_BMP_AGENCY_MAKER_SEARCH_HEALTH_AGENCY_LIST,
  REQUEST_DETAIL_BMP_AGENCY_MAKER_SEARCH_HEALTH_AGENCY_LIST_SUCCESS,
  REQUEST_DETAIL_BMP_AGENCY_MAKER_SEARCH_HEALTH_AGENCY_LIST_ERROR,
  REQUEST_DETAIL_BMP_AGENCY_MAKER_ADD_SET,
  REQUEST_DETAIL_BMP_AGENCY_MAKER_ADD_MODAL_SET,
  REQUEST_DETAIL_BMP_AGENCY_MAKER_ADD,
  REQUEST_DETAIL_BMP_AGENCY_MAKER_ADD_SUCCESS,
  REQUEST_DETAIL_BMP_AGENCY_MAKER_ADD_ERROR,
  REQUEST_DETAIL_BMP_AGENCY_MAKER_ADD_CHANGE,
  REQUEST_DETAIL_BMP_AGENCY_MAKER_DETAIL,
  REQUEST_DETAIL_BMP_AGENCY_MAKER_DETAIL_SUCCESS,
  REQUEST_DETAIL_BMP_AGENCY_MAKER_DETAIL_ERROR,
  REQUEST_DETAIL_BMP_AGENCY_MAKER_VIEW_MODAL_SET,
  REQUEST_DETAIL_BMP_AGENCY_MAKER_EDIT_CHANGE,
  REQUEST_DETAIL_BMP_AGENCY_MAKER_EDIT_MODAL_SET,
  REQUEST_DETAIL_BMP_AGENCY_MAKER_UPDATE,
  REQUEST_DETAIL_BMP_AGENCY_MAKER_UPDATE_SUCCESS,
  REQUEST_DETAIL_BMP_AGENCY_MAKER_UPDATE_ERROR,
  REQUEST_DETAIL_BMP_AGENCY_MAKER_VIEW_TAB_SET
} from '../actions';

export const NAME = 'request_detail_bmp_agency_maker';

export const RequestDetailBmpAgencyMakerList = (page, num_page, orderBy, filters, stateFilter, display) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_MAKER_LIST,
  payload: { page: page, num_page: num_page, orderBy: orderBy, filters: filters, filtersState: stateFilter, display: display }
});

export const RequestDetailBmpAgencyMakerListSuccess = (data) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_MAKER_LIST_SUCCESS,
  payload: data
});

export const RequestDetailBmpAgencyMakerListError = (error) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_MAKER_LIST_ERROR,
  payload: error
});

export const RequestDetailBmpAgencyMakerPageSet = (data) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_MAKER_PAGE_SET,
  payload: data
});

export const RequestDetailBmpAgencyMakerSearchSet = (data) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_MAKER_SEARCH_SET,
  payload: data
});

export const RequestDetailBmpAgencyMakerSearchChangeSet = (data) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_MAKER_SEARCH_CHANGE,
  payload: data
});

export const RequestDetailBmpAgencyMakerSearchGeoCountryList = (data) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_MAKER_SEARCH_GEO_COUNTRY_LIST,
  payload: data
});

export const RequestDetailBmpAgencyMakerSearchGeoCountryListSuccess = (message) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_MAKER_SEARCH_GEO_COUNTRY_LIST_SUCCESS,
  payload: message
});

export const RequestDetailBmpAgencyMakerSearchGeoCountryListError = (error) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_MAKER_SEARCH_GEO_COUNTRY_LIST_ERROR,
  payload: error
});

export const RequestDetailBmpAgencyMakerSearchHealthAgencyList = (data) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_MAKER_SEARCH_HEALTH_AGENCY_LIST,
  payload: data
});

export const RequestDetailBmpAgencyMakerSearchHealthAgencyListSuccess = (message) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_MAKER_SEARCH_HEALTH_AGENCY_LIST_SUCCESS,
  payload: message
});

export const RequestDetailBmpAgencyMakerSearchHealthAgencyListError = (error) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_MAKER_SEARCH_HEALTH_AGENCY_LIST_ERROR,
  payload: error
});

export const RequestDetailBmpAgencyMakerAddChangeSet = (data) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_MAKER_ADD_CHANGE,
  payload: data
});

export const RequestDetailBmpAgencyMakerAddSet = (data) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_MAKER_ADD_SET,
  payload: data
});

export const RequestDetailBmpAgencyMakerAddModalSet = (data) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_MAKER_ADD_MODAL_SET,
  payload: data
});

export const RequestDetailBmpAgencyMakerAdd = (data) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_MAKER_ADD,
  payload: data
});

export const RequestDetailBmpAgencyMakerAddSuccess = (message) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_MAKER_ADD_SUCCESS,
  payload: message
});

export const RequestDetailBmpAgencyMakerAddError = (error) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_MAKER_ADD_ERROR,
  payload: error
});

export const RequestDetailBmpAgencyMakerDetail = (data) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_MAKER_DETAIL,
  payload: data
});

export const RequestDetailBmpAgencyMakerDetailSuccess = (data) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_MAKER_DETAIL_SUCCESS,
  payload: data
});

export const RequestDetailBmpAgencyMakerDetailError = (error) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_MAKER_DETAIL_ERROR,
  payload: error
});

export const RequestDetailBmpAgencyMakerViewModalSet = (data) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_MAKER_VIEW_MODAL_SET,
  payload: data
});

export const RequestDetailBmpAgencyMakerEditModalSet = (data) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_MAKER_EDIT_MODAL_SET,
  payload: data
});

export const RequestDetailBmpAgencyMakerEditChangeSet = (data) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_MAKER_EDIT_CHANGE,
  payload: data
});

export const RequestDetailBmpAgencyMakerUpdate = (data) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_MAKER_UPDATE,
  payload: data
});

export const RequestDetailBmpAgencyMakerUpdateSuccess = (message) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_MAKER_UPDATE_SUCCESS,
  payload: message.message
});

export const RequestDetailBmpAgencyMakerUpdateError = (error) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_MAKER_UPDATE_ERROR,
  payload: error
});

export const RequestDetailBmpAgencyMakerViewTabSet = (data) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_MAKER_VIEW_TAB_SET,
  payload: data
});

const request_detail_bmp_agency_maker = (state) => state[NAME];

export const RequestDetailBmpAgencyMakerSelector = createStructuredSelector({
  request_detail_bmp_agency_maker
})
