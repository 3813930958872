import { createStructuredSelector } from 'reselect'

import {
  REQUEST_DETAIL_BMP_AGENCY_PACKAGER_LIST,
  REQUEST_DETAIL_BMP_AGENCY_PACKAGER_LIST_SUCCESS,
  REQUEST_DETAIL_BMP_AGENCY_PACKAGER_LIST_ERROR,
  REQUEST_DETAIL_BMP_AGENCY_PACKAGER_PAGE_SET,
  REQUEST_DETAIL_BMP_AGENCY_PACKAGER_SEARCH_SET,
  REQUEST_DETAIL_BMP_AGENCY_PACKAGER_SEARCH_CHANGE,
  REQUEST_DETAIL_BMP_AGENCY_PACKAGER_SEARCH_GEO_COUNTRY_LIST,
  REQUEST_DETAIL_BMP_AGENCY_PACKAGER_SEARCH_GEO_COUNTRY_LIST_SUCCESS,
  REQUEST_DETAIL_BMP_AGENCY_PACKAGER_SEARCH_GEO_COUNTRY_LIST_ERROR,
  REQUEST_DETAIL_BMP_AGENCY_PACKAGER_SEARCH_HEALTH_AGENCY_LIST,
  REQUEST_DETAIL_BMP_AGENCY_PACKAGER_SEARCH_HEALTH_AGENCY_LIST_SUCCESS,
  REQUEST_DETAIL_BMP_AGENCY_PACKAGER_SEARCH_HEALTH_AGENCY_LIST_ERROR,
  REQUEST_DETAIL_BMP_AGENCY_PACKAGER_ADD_SET,
  REQUEST_DETAIL_BMP_AGENCY_PACKAGER_ADD_MODAL_SET,
  REQUEST_DETAIL_BMP_AGENCY_PACKAGER_ADD,
  REQUEST_DETAIL_BMP_AGENCY_PACKAGER_ADD_SUCCESS,
  REQUEST_DETAIL_BMP_AGENCY_PACKAGER_ADD_ERROR,
  REQUEST_DETAIL_BMP_AGENCY_PACKAGER_ADD_CHANGE,
  REQUEST_DETAIL_BMP_AGENCY_PACKAGER_DETAIL,
  REQUEST_DETAIL_BMP_AGENCY_PACKAGER_DETAIL_SUCCESS,
  REQUEST_DETAIL_BMP_AGENCY_PACKAGER_DETAIL_ERROR,
  REQUEST_DETAIL_BMP_AGENCY_PACKAGER_VIEW_MODAL_SET,
  REQUEST_DETAIL_BMP_AGENCY_PACKAGER_EDIT_CHANGE,
  REQUEST_DETAIL_BMP_AGENCY_PACKAGER_EDIT_MODAL_SET,
  REQUEST_DETAIL_BMP_AGENCY_PACKAGER_UPDATE,
  REQUEST_DETAIL_BMP_AGENCY_PACKAGER_UPDATE_SUCCESS,
  REQUEST_DETAIL_BMP_AGENCY_PACKAGER_UPDATE_ERROR,
  REQUEST_DETAIL_BMP_AGENCY_PACKAGER_VIEW_TAB_SET
} from '../actions';

export const NAME = 'request_detail_bmp_agency_packager';

export const RequestDetailBmpAgencyPackagerList = (page, num_page, orderBy, filters, stateFilter, display) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_PACKAGER_LIST,
  payload: { page: page, num_page: num_page, orderBy: orderBy, filters: filters, filtersState: stateFilter, display: display }
});

export const RequestDetailBmpAgencyPackagerListSuccess = (data) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_PACKAGER_LIST_SUCCESS,
  payload: data
});

export const RequestDetailBmpAgencyPackagerListError = (error) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_PACKAGER_LIST_ERROR,
  payload: error
});

export const RequestDetailBmpAgencyPackagerPageSet = (data) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_PACKAGER_PAGE_SET,
  payload: data
});

export const RequestDetailBmpAgencyPackagerSearchSet = (data) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_PACKAGER_SEARCH_SET,
  payload: data
});

export const RequestDetailBmpAgencyPackagerSearchChangeSet = (data) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_PACKAGER_SEARCH_CHANGE,
  payload: data
});

export const RequestDetailBmpAgencyPackagerSearchGeoCountryList = (data) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_PACKAGER_SEARCH_GEO_COUNTRY_LIST,
  payload: data
});

export const RequestDetailBmpAgencyPackagerSearchGeoCountryListSuccess = (message) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_PACKAGER_SEARCH_GEO_COUNTRY_LIST_SUCCESS,
  payload: message
});

export const RequestDetailBmpAgencyPackagerSearchGeoCountryListError = (error) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_PACKAGER_SEARCH_GEO_COUNTRY_LIST_ERROR,
  payload: error
});

export const RequestDetailBmpAgencyPackagerSearchHealthAgencyList = (data) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_PACKAGER_SEARCH_HEALTH_AGENCY_LIST,
  payload: data
});

export const RequestDetailBmpAgencyPackagerSearchHealthAgencyListSuccess = (message) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_PACKAGER_SEARCH_HEALTH_AGENCY_LIST_SUCCESS,
  payload: message
});

export const RequestDetailBmpAgencyPackagerSearchHealthAgencyListError = (error) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_PACKAGER_SEARCH_HEALTH_AGENCY_LIST_ERROR,
  payload: error
});

export const RequestDetailBmpAgencyPackagerAddChangeSet = (data) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_PACKAGER_ADD_CHANGE,
  payload: data
});

export const RequestDetailBmpAgencyPackagerAddSet = (data) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_PACKAGER_ADD_SET,
  payload: data
});

export const RequestDetailBmpAgencyPackagerAddModalSet = (data) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_PACKAGER_ADD_MODAL_SET,
  payload: data
});

export const RequestDetailBmpAgencyPackagerAdd = (data) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_PACKAGER_ADD,
  payload: data
});

export const RequestDetailBmpAgencyPackagerAddSuccess = (message) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_PACKAGER_ADD_SUCCESS,
  payload: message
});

export const RequestDetailBmpAgencyPackagerAddError = (error) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_PACKAGER_ADD_ERROR,
  payload: error
});

export const RequestDetailBmpAgencyPackagerDetail = (data) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_PACKAGER_DETAIL,
  payload: data
});

export const RequestDetailBmpAgencyPackagerDetailSuccess = (data) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_PACKAGER_DETAIL_SUCCESS,
  payload: data
});

export const RequestDetailBmpAgencyPackagerDetailError = (error) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_PACKAGER_DETAIL_ERROR,
  payload: error
});

export const RequestDetailBmpAgencyPackagerViewModalSet = (data) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_PACKAGER_VIEW_MODAL_SET,
  payload: data
});

export const RequestDetailBmpAgencyPackagerEditModalSet = (data) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_PACKAGER_EDIT_MODAL_SET,
  payload: data
});

export const RequestDetailBmpAgencyPackagerEditChangeSet = (data) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_PACKAGER_EDIT_CHANGE,
  payload: data
});

export const RequestDetailBmpAgencyPackagerUpdate = (data) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_PACKAGER_UPDATE,
  payload: data
});

export const RequestDetailBmpAgencyPackagerUpdateSuccess = (message) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_PACKAGER_UPDATE_SUCCESS,
  payload: message.message
});

export const RequestDetailBmpAgencyPackagerUpdateError = (error) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_PACKAGER_UPDATE_ERROR,
  payload: error
});

export const RequestDetailBmpAgencyPackagerViewTabSet = (data) => ({
  type: REQUEST_DETAIL_BMP_AGENCY_PACKAGER_VIEW_TAB_SET,
  payload: data
});

const request_detail_bmp_agency_packager = (state) => state[NAME];

export const RequestDetailBmpAgencyPackagerSelector = createStructuredSelector({
  request_detail_bmp_agency_packager
})
