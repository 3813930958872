import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authFB } from '../../helpers/Firebase';
import { servicePath } from '../../constants/defaultValues';
import { NotificationManager } from '../../components/common/react-notifications';
import { 
  REQUEST_DETAIL_BMP_AGENCY_PACKAGER_LIST,
  REQUEST_DETAIL_BMP_AGENCY_PACKAGER_SEARCH_GEO_COUNTRY_LIST,
  REQUEST_DETAIL_BMP_AGENCY_PACKAGER_SEARCH_HEALTH_AGENCY_LIST,
  REQUEST_DETAIL_BMP_AGENCY_PACKAGER_ADD,
  REQUEST_DETAIL_BMP_AGENCY_PACKAGER_DETAIL,
  REQUEST_DETAIL_BMP_AGENCY_PACKAGER_UPDATE,
} from '../actions';
import {
  RequestDetailBmpAgencyPackagerListSuccess,
  RequestDetailBmpAgencyPackagerListError,
  RequestDetailBmpAgencyPackagerSearchGeoCountryListSuccess,
  RequestDetailBmpAgencyPackagerSearchGeoCountryListError,
  RequestDetailBmpAgencyPackagerSearchHealthAgencyListSuccess,
  RequestDetailBmpAgencyPackagerSearchHealthAgencyListError,
  RequestDetailBmpAgencyPackagerAddSuccess,
  RequestDetailBmpAgencyPackagerAddError,
  RequestDetailBmpAgencyPackagerDetailSuccess,
  RequestDetailBmpAgencyPackagerDetailError,
  RequestDetailBmpAgencyPackagerUpdateSuccess,
  RequestDetailBmpAgencyPackagerUpdateError
} from '../actions';
 
export function* watchRequestDetailBmpAgencyPackagerList() {
  yield takeEvery(REQUEST_DETAIL_BMP_AGENCY_PACKAGER_LIST, onRequestDetailBmpAgencyPackagerList);
}

export function* watchRequestDetailBmpAgencyPackagerSearchHealthAgencyList() {
  yield takeEvery(REQUEST_DETAIL_BMP_AGENCY_PACKAGER_SEARCH_HEALTH_AGENCY_LIST, onRequestDetailBmpAgencyPackagerSearchHealthAgencyList);
}

export function* watchRequestDetailBmpAgencyPackagerSearchGeoCountryList() {
  yield takeEvery(REQUEST_DETAIL_BMP_AGENCY_PACKAGER_SEARCH_GEO_COUNTRY_LIST, onRequestDetailBmpAgencyPackagerSearchGeoCountryList);
}

export function* watchRequestDetailBmpAgencyPackagerAdd() {
  yield takeEvery(REQUEST_DETAIL_BMP_AGENCY_PACKAGER_ADD, onRequestDetailBmpAgencyPackagerAdd);
}

export function* watchRequestDetailBmpAgencyPackagerDetail() {
  yield takeEvery(REQUEST_DETAIL_BMP_AGENCY_PACKAGER_DETAIL, onRequestDetailBmpAgencyPackagerDetail);
}

export function* watchRequestDetailBmpAgencyPackagerUpdate() {
  yield takeEvery(REQUEST_DETAIL_BMP_AGENCY_PACKAGER_UPDATE, onRequestDetailBmpAgencyPackagerUpdate);
}

const onRequestDetailBmpAgencyPackagerListAsync  = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_detail_bmp_agency_packager/list', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

const onRequestDetailBmpAgencyPackagerUpdateAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_detail_bmp_agency_packager/update', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json', 'token': token },
      body: JSON.stringify(data)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

function getToken(auth) {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      user.getIdToken().then((token) => {
        unsubscribe();
        resolve(token);
      });
    }, reject);
  });
}


const onRequestDetailBmpAgencyPackagerAddAsync = async (data) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_detail_bmp_agency_packager/add', {
      method: 'POST', mode: 'cors', cache: 'no-cache',
      headers: { 'Content-Type': 'application/json', 'token': token },
      body: JSON.stringify(data.payload)
  })
      .then(response => response.json())
      .then(data => { return data; })
      .catch(err => console.log(err));
  return { message: true, data: response };
};

const onRequestDetailBmpAgencyPackagerSearchGeoCountryListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/geo_country', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onRequestDetailBmpAgencyPackagerSearchHealthAgencyListAsync = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'search/health_agency', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return response ;
};

const onRequestDetailBmpAgencyPackagerDetailAsync  = async (payload) => {
  let token = await getToken(authFB);
  const response = await fetch(servicePath + 'request_detail_bmp_agency_packager/detail', {
    method: 'POST', mode: 'cors', cache: 'no-cache',
    headers: { 'Content-Type': 'application/json', 'token': token },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => { return data; })
    .catch(err => console.log(err));
  return { "data": response };
};

function* onRequestDetailBmpAgencyPackagerList(data) {
  try {
    const response = yield call(onRequestDetailBmpAgencyPackagerListAsync, data.payload);
    yield put(RequestDetailBmpAgencyPackagerListSuccess(response.data));
  } catch (error) {
    yield put(RequestDetailBmpAgencyPackagerListError(error));
  }
}

function* onRequestDetailBmpAgencyPackagerSearchHealthAgencyList(data) {
  try {
    const response = yield call(onRequestDetailBmpAgencyPackagerSearchHealthAgencyListAsync, data.payload);
    yield put(RequestDetailBmpAgencyPackagerSearchHealthAgencyListSuccess(response.data));
  } catch (error) {
    yield put(RequestDetailBmpAgencyPackagerSearchHealthAgencyListError(error));
  }
}

function* onRequestDetailBmpAgencyPackagerSearchGeoCountryList(data) {
  try {
    const response = yield call(onRequestDetailBmpAgencyPackagerSearchGeoCountryListAsync, data.payload);
    yield put(RequestDetailBmpAgencyPackagerSearchGeoCountryListSuccess(response.data));
  } catch (error) {
    yield put(RequestDetailBmpAgencyPackagerSearchGeoCountryListError(error));
  }
}

function* onRequestDetailBmpAgencyPackagerAdd(data) {
  try {
      const response = yield call(onRequestDetailBmpAgencyPackagerAddAsync, data);
      console.log(response)
      if (response.data.status === true) {
          NotificationManager.success(response.data.message, 'Exito', 3000, null, null, '');
      } else if (response.data.status === 'error') {
          NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(RequestDetailBmpAgencyPackagerAddSuccess(response.data));
  } catch (error) {
      yield put(RequestDetailBmpAgencyPackagerAddError(error));
      NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
  }
}

function* onRequestDetailBmpAgencyPackagerDetail(data) {
  try {
    const response = yield call(onRequestDetailBmpAgencyPackagerDetailAsync, data.payload);
    yield put(RequestDetailBmpAgencyPackagerDetailSuccess(response.data.data));
  } catch (error) {
    yield put(RequestDetailBmpAgencyPackagerDetailError(error));
  }
}

function* onRequestDetailBmpAgencyPackagerUpdate(data) {
  try {
      const response = yield call(onRequestDetailBmpAgencyPackagerUpdateAsync, data.payload);
      if (response.data.status === true) {
          NotificationManager.success(response.data.message, 'Exito', 3000, null, null, '');
      } else if (response.data.status === 'error') {
          NotificationManager.error(response.data.message, 'Error', 3000, null, null, '');
      }
      yield put(RequestDetailBmpAgencyPackagerUpdateSuccess(response.data));
  } catch (error) {
      yield put(RequestDetailBmpAgencyPackagerUpdateError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchRequestDetailBmpAgencyPackagerList),
    fork(watchRequestDetailBmpAgencyPackagerSearchGeoCountryList),
    fork(watchRequestDetailBmpAgencyPackagerSearchHealthAgencyList),
    fork(watchRequestDetailBmpAgencyPackagerAdd),
    fork(watchRequestDetailBmpAgencyPackagerDetail),
    fork(watchRequestDetailBmpAgencyPackagerUpdate),
  ]);
} 